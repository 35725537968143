export const Textos={
    dadoImagen: "Imagen de dado con el número",
        listaPlaceholder: "Perro\nGato\nPez",
        litaInstrucciones:
          "Introduce la palabra que quieras por ejemplo 'Perro' después da enter para introducir la segunda palabra 'Gato' y dale a JUGAR",
        generadoSN: "Generador de Sí o No",
        numeroRandom: "Número Random",
        dado: "Dado",
        numeroDados:"Número de dados",
        listaRandom: "Lista Random",
        boton: "JUGAR",
        rango:"Rango",
        contacto:"Contacto",
        donaciones:"Donaciones",
        email:"herz_rot_slomana@hotmail.com",
        otraVez:"OTRA VEZ",
        alertaLista:"Por favor introduce el texto en el recuadro blanco.",
        paypal:"https://www.paypal.me/herzRot",
        /**Ingles */
        diceImage: "image of dice with number",
        listPlaceholder: "Dog\nCat\nFish",
        listinstructions:
          "Enter the word do you want, for example 'Dog' then hit enter to enter the second word 'Cat' and hit PLAY",
        generatorSN: "Yes or No Generator",
        randomNumber: "Random Number",
        dice: "Dice",
        diceNumber:"Dice Number",
        randomList: "Random List",
        button: "PLAY",
        range:"Range",
        contact:"Contact",
        donations:"Donations",
        again:"AGAIN",
        alertList:"Please enter the text in the white box."

}