import { Route,Routes,HashRouter } from "react-router-dom"
import { Footer } from "../Componentes/Layout/Footer"
import { Header } from "../Componentes/Layout/Header"
import { DadoPagina } from "../Componentes/Pages/DadoPagina"
import { GeneradorSiNoPagina } from "../Componentes/Pages/GeneradorSiNoPagina"
import { ListaRandomPagina } from "../Componentes/Pages/ListaRandomPagina"
import { NumeroPagina } from "../Componentes/Pages/NumeroPagina"
export const Rutas=()=>{
    return(
        <HashRouter>
            <Header/>
        <section className="content">
            <Routes>
                <Route path="/" element={<DadoPagina/>}/>
                <Route path="/Dado" element={<DadoPagina/>}/>
                <Route path="/GeneradorSiNo" element={<GeneradorSiNoPagina/>}/>
                <Route path="/Numero" element={<NumeroPagina/>}/>
                <Route path="/Lista" element={<ListaRandomPagina/>}/>
            </Routes>
        </section>
        <Footer/>
        </HashRouter>
    );
}