export const useIdioma = () => {

    //--------------------------------------------------------------------------------------------
    const detectarIdioma = () => {
        const navegadorLenguaje = navigator.language || navigator.languages;
        if (navegadorLenguaje.includes("en")) {
          return "ingles";
        }
        return "español";
      };
      //----------------------
    return{
        detectarIdioma
 }
}
