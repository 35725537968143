import { useEffect } from "react";
import "../../Hoja_de_estilos/Dado.css";
import {Textos} from '../../Global/Textos'
import { useIdioma } from "../../Hooks/useIdioma";
export const Dado = ({ valor,valorRandom,valoresRandom,setValoresRandom }) => {
//---------------------------------------------------
const{detectarIdioma}=useIdioma();
useEffect(()=>{
  selector();
  {console.log(valor)}
},[valor]);
//------------------------------------------------------
const selector=()=>{
  try {
    const rango=parseInt(valor);
    if(rango>1){
      let arreglo=[];
      for(let i=1; i<=rango;i++){
        arreglo.push(i);
      }
      setValoresRandom(arreglo);
      // console.log(arreglo.length)
    } 
  } catch (error) {
    console.log("-----------------------------")
    console.log(error)
  }
}
//------------------------------------------------------
  const arreglo = [];
  for (let i = 1; i <= valor; i++) {
    arreglo.push(valor);
  }
  const imagenes=(index)=>{
  
   try {
     return (
      <>
      <img className="" src={require(`../../imagenes/Dado_${valoresRandom[index]}.svg`)} alt={detectarIdioma()==="español" ? Textos.dadoImagen+" "+valoresRandom[index]:Textos.diceImage+" "+valoresRandom[index]}></img>
      {/* {console.log(`../imagenes/cara_${valoresRandom[index]}.png`)} */}
      </>
      //<img className="" src={require(`../imagenes/cara_${valoresRandom[index]}.png`)}></img>
    )
   } catch (error) {
    console.log("--------------------------------------------")
    console.log(error)
    // console.log(`../../imagenes/Dado_${valoresRandom[index]}.svg`)
   }
  }
//---------------------------------------------------
//error en un dado posible solucion incluir el 1 en el metodo imagenes
  return (
    <div className="dado-contenedor">
      {arreglo.map((mostrar, id) => {
        return (
          <div className="dado-contenedor-img" key={id}>
            {/* <!--Imagenes de los dados--> */}
           {valor==1 ? (
            <>
            <img className="" src={require(`../../imagenes/Dado_${valorRandom}.svg`)} alt={detectarIdioma()==="español" ? Textos.dadoImagen+" "+valoresRandom[valor]:Textos.diceImage+" "+valoresRandom[valor]}></img>
            </>
           ): imagenes(id) }
          </div>
        );
      })}
    </div>
  );
};
