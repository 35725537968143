import React, { useState } from 'react'
import { Textos } from '../../Global/Textos';
import '../../Hoja_de_estilos/NumeroRandom.css'
import { useIdioma } from '../../Hooks/useIdioma'
export const Numero = ({random,setRango}) => {
  const{detectarIdioma}=useIdioma();
  return (
    <div className='contenedorNum'>
        <div className='contenedorNum-texto'>
            <h1 className='numeroH1'>{random}</h1>
        </div>
        <div>
          <h2>{detectarIdioma()==="español"?Textos.rango:Textos.range}</h2>
          <input type={"number"} min={1} max={1000} name="valor" onChange={(e) => setRango(e.target.value)}/>
        </div>
    </div>
  )
}
