import React from 'react'
import { Textos } from '../../Global/Textos';
import { useIdioma } from '../../Hooks/useIdioma';
import { useNumeroRandom } from '../../Hooks/useNumeroRandom'
export const BotonLista = ({tamaño,setLista,setBandera,bandera}) => {
//--------------------------------------------------------------
  const {listaRandom}=useNumeroRandom();
  const {detectarIdioma}=useIdioma();
  //--------------------------------------------------------------
  const genera=()=>{
    //valida si hay elementos en el text area
    if(tamaño.length==0){
      return( alert(detectarIdioma()==="español"?Textos.alertaLista:Textos.alertaLista));
    }
    let long=parseInt(tamaño);
    let numeros=listaRandom(long);
    setLista(numeros);
    setBandera(false);
  }
  const recarga=()=>{
    window.location.reload();
  }
  //--------------------------------------------------------------
  return (
    <div className='boton-jugar'>
      {bandera ? <button className='boton' onClick={genera}>{detectarIdioma()==="español"?Textos.boton:Textos.button}</button>:
      (
        <>
        <button className='boton' onClick={recarga}>{detectarIdioma()==="español"?Textos.otraVez:Textos.again}</button>
        <button className='boton-repetir' onClick={genera}><img src={require("../../imagenes/repetir.ico")}/></button>
        </>
      )
      }
    </div>
  )
}