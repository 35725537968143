import React, { useState } from "react";
import { Textos } from "../../Global/Textos";
import "../../Hoja_de_estilos/Lista.css";
import { useIdioma } from "../../Hooks/useIdioma";
export const Lista = ({ setTamaño, lista, bandera }) => {
  //---------------------------------------------------------------------------
  const [arreglo, setArreglo] = useState([]);
  const { detectarIdioma } = useIdioma();
  //---------------------------------------------------------------------------
  const getLista = (e) => {
    let cadena = e.target.value;
    let corte = cadena.split("\n");
    let nuevo = [];
    for (let i = 0; i < corte.length; i++) {
      if (corte[i]) {
        nuevo.push(corte[i]);
      }
    }
    setArreglo(nuevo);
    setTamaño(nuevo.length);
  };
  //-------------------------------------------------------------------------------
  const recorrer = () => {
    let nuevo = [];
    for (let i = 0; i < lista.length; i++) {
      nuevo.push(arreglo[lista[i]]);
    }

    const listElements = nuevo.map((novo, index) => (
      <h1 key={index}>
        <li>{novo}</li>
      </h1>
    ));

    return (
      <div>
        <ol>{listElements}</ol>
      </div>
    );
  };
  //---------------------------------------------------------------------------
  return (
    <div className="contenedor">
      <div className="contenedorArea">
        {bandera ? (
          <>
            <textarea name="area" onChange={getLista} placeholder={ detectarIdioma() === "español" ? Textos.listaPlaceholder: Textos.listPlaceholder}/>
            <div className="intrucciones-contenedor">
              <p>
                {detectarIdioma() === "español" ? Textos.litaInstrucciones: Textos.listinstructions}
              </p>
            </div>
          </>
        ) : (
          recorrer()
        )}
      </div>
    </div>
  );
};
