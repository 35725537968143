import { Textos } from '../../Global/Textos';
import '../../Hoja_de_estilos/BotonLanzar.css'
import { useIdioma } from '../../Hooks/useIdioma';
import { useNumeroRandom } from '../../Hooks/useNumeroRandom'


export const BotonLanzar=({setValorRandom,valor,setValoresRandom})=> {
  
  const {numRandom,numerosRandom}=useNumeroRandom();
  const {detectarIdioma}=useIdioma();
  const numero=()=>{
    if(valor>1){
    
    let areglo=numerosRandom(valor)
    setValoresRandom(areglo);
    }
    
    let numeroObtenido=numRandom(6);
      setValorRandom(numeroObtenido);
  }

  return (
    <div className="boton-jugar">
            <button className="boton" type="button" onClick={numero}>{detectarIdioma()==="español" ? Textos.boton : Textos.button}</button>
    </div>
  )
}