import React from "react";
import { Textos } from "../../Global/Textos";
import "../../Hoja_de_estilos/RangoDado.css";
import { useIdioma } from "../../Hooks/useIdioma";
export const Rango=({valor,setValor})=> {
  const {detectarIdioma}=useIdioma();
  return (
    <div className="rango-contenedor">
      <div className="rango">
        <div className="parrafo-contenedor">
          <p>{detectarIdioma()==="español"?Textos.numeroDados:Textos.diceNumber}</p>
        </div> 
        <input
          className="barra-rango"
          type="range"
          onChange={(e) => setValor(e.target.value)}
          min="1"
          max="6"
        />
        <div className="div-span">
          <span className="span-num">{valor}</span>
        </div>
      </div>
    </div>
  );
}