import { useState } from "react";
import "../../Hoja_de_estilos/Header.css";
import "../../Hoja_de_estilos/Menu_Hamburguesa.css";
import { MenuJuegos } from "./MenuJuegos";

export const Header = () => {
  //-----------------------------------------------------------
  const[estado,setEstado]=useState(false);
  //-----------------------------------------------------------
  const captura = () => {
    const linea1 = document.querySelector(".line1-barra-menu");
    const linea2 = document.querySelector(".line2-barra-menu");
    const linea3 = document.querySelector(".line3-barra-menu");
    const menu = document.querySelector(".menu-contenedor-nav");
    linea1.classList.toggle("activeline1-barra-menu");
    linea2.classList.toggle("activeline2-barra-menu");
    linea3.classList.toggle("activeline3-barra-menu");
    menu.classList.toggle("active");
    setEstado(!estado); //valor contrario
  };
  return (
    <header>
      <div className="logo-div">
        <a href="/" className="hedear-a">
        <img src={require("../../imagenes/logo_RandomVG.png") }alt=""/>
        </a>
         <p className="Nombre">RANDOM-VG</p> 
      </div>

      <div className="barra-menu" onClick={captura}>
        <span className="line1-barra-menu"></span>
        <span className="line2-barra-menu"></span>
        <span className="line3-barra-menu"></span>
      </div>

      <MenuJuegos estado={estado}/>
    </header>
  );
};
