import React from 'react'
import { Textos } from '../../Global/Textos';
import { useIdioma } from '../../Hooks/useIdioma';
import { useNumeroRandom } from '../../Hooks/useNumeroRandom'

export const BotonLanzarSN = ({setRandom}) => {
  const {detectarIdioma}=useIdioma();
  const {numRandom}=useNumeroRandom();
  //----------------------------------------------------------------------------------------
  const genera=()=>{
    const num=numRandom(2);
    setRandom(num);
  }
  return (
    <div className='boton-jugar'>
        <button className='boton' onClick={genera}>{detectarIdioma()==="español" ? Textos.boton : Textos.button}</button>
    </div>
  )
}
