import {React,useState} from 'react'
import { BotonLanzar } from '../ComponentesDado/BotonLanzar';
import { Dado } from '../ComponentesDado/Dado'
import { Rango } from '../ComponentesDado/Rango'

export const DadoPagina = () => {
  const [valor, setValor] = useState(1);
  const [valorRandom,setValorRandom]=useState(1);
  const [valoresRandom,setValoresRandom]=useState([1,2,3,4,5,6]);
  return (
    <main>
      <Rango valor={valor} setValor={setValor}/>
      <Dado valor={valor} valorRandom={valorRandom} valoresRandom={valoresRandom} setValoresRandom={setValoresRandom}/>
      <BotonLanzar valor={valor} setValorRandom={setValorRandom} setValoresRandom={setValoresRandom} />
      
    </main>
  )
}
