import React, { useState } from 'react'
import { BotonJugar } from '../Numero/BotonJugar'
import { Numero } from '../Numero/Numero'

export const NumeroPagina = () => {
  const [random,setRandom]=useState(1);
  const [rango,setRango]=useState(1);
  return (
    <main>
      <Numero random={random} setRango={setRango}/>
      <BotonJugar setRandom={setRandom} rango={rango}/>
      </main>
  )
}

