import React, { useEffect } from 'react'
import '../../Hoja_de_estilos/GeneradorSN.css'
import { useIdioma } from '../../Hooks/useIdioma'
export const Generador = ({random}) => {
  const {detectarIdioma} =useIdioma();
  return (
    
        <div className='contenedor-SN'>
            <div className='contenedor-imagen-SN'>
              {detectarIdioma()=="español"?
            <img className="imagen" src={require(`../../imagenes/generador_${random}.png`)}></img>
              :
              <img className="imagen" src={require(`../../imagenes/generator_${random}.png`)}></img>
              }
            </div>
        </div>
  )
}
