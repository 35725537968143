import React, { useState } from 'react'
import { BotonLanzarSN } from '../ComponentesSiNo/BotonLanzarSN'
import { Generador } from '../ComponentesSiNo/Generador'

export const GeneradorSiNoPagina = () => {
  const [random,setRandom]=useState(1);
  return (
    <main>
      <Generador random={random}/>
      <BotonLanzarSN setRandom={setRandom}/>
    </main>
  )
}
