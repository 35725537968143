import { Textos } from '../../Global/Textos';
import '../../Hoja_de_estilos/Footer.css'
import { useIdioma } from '../../Hooks/useIdioma';
export const Footer=()=>{
    const {detectarIdioma}=useIdioma();
    return(
       <footer>
        <div className="contacto">
            <div>
                <h1>{detectarIdioma()==="español"?Textos.contacto:Textos.contact}</h1>
                <a className='contacto-a' href='https://outlook.live.com/' target={"_blank"} rel="noopener noreferrer"><span></span>{Textos.email}</a> 
            </div>
        </div>
        <div className="donaciones">
            <div>
                <h1>{detectarIdioma()==="español"?Textos.donaciones:Textos.donations}</h1>
                <a className='donaciones-a' href='https://www.paypal.me/herzRot' target={"_blank"} rel="noopener noreferrer"><span></span>{Textos.paypal}</a>
            </div>
        </div>
       </footer> 
    );
}