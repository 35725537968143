import { NavLink } from 'react-router-dom';
import { Textos } from '../../Global/Textos';
import '../../Hoja_de_estilos/Header.css'
import { useIdioma } from '../../Hooks/useIdioma';
export  const MenuJuegos=({estado})=>{
    const{detectarIdioma}=useIdioma();
    return(
        <nav className={`menu-contenedor-nav ${estado?'Activo':''} mediano`}>
            <ul>
                <li>
                    <NavLink to="/GeneradorSiNo">{detectarIdioma()==="español"?Textos.generadoSN:Textos.generatorSN}</NavLink>
                </li>
                <li>
                    <NavLink to="/Numero">{detectarIdioma()==="español"?Textos.numeroRandom:Textos.randomNumber}</NavLink>
                </li>
                <li>
                    <NavLink to="/Dado">{detectarIdioma()==="español"?Textos.dado:Textos.dice}</NavLink>
                </li>
                <li>
                    <NavLink to="/Lista">{detectarIdioma()==="español"?Textos.listaRandom:Textos.randomList}</NavLink>
                </li>
            </ul>
        </nav>
    );
}