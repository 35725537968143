import React from 'react'
import { Textos } from '../../Global/Textos';
import { useIdioma } from '../../Hooks/useIdioma';
import { useNumeroRandom } from '../../Hooks/useNumeroRandom'

export const BotonJugar = ({setRandom,rango}) => {
    const {numRandom}=useNumeroRandom();
    const{detectarIdioma}=useIdioma();
    const numero=()=>{
        const num=numRandom(rango);
        setRandom(num)
        console.log(num)
    }
  return (
    <div className='boton-jugar'>
        <button className='boton' onClick={numero}>{detectarIdioma()==="español"?Textos.boton:Textos.boton}</button>
    </div>
  )
}
