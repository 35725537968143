export const useNumeroRandom=()=>{

  //----------------------------------------------------------------------------
  const numRandom=(valor)=>{
    const num=parseInt(valor)
      let numero_aleatorio= Math.floor(Math.random()*num)+1;
      return numero_aleatorio
  }
  //----------------------------------------------------------------------------
  const numerosRandom=(valor)=>{
    let arreglo=[];
    for(let i=1;i<=valor;i++){
        arreglo.push(Math.floor(Math.random()*6)+1);
    }
    return arreglo;
  }
  //----------------------------------------------------------------------------
  const listaRandom=(valor)=>{
    let arreglo=[]
    for(let i=0;i<valor;i++){
      arreglo.push(i);
    }
    //Desordenar arreglo
    console.log(arreglo.sort(()=>Math.random()-0.5));
    //console.log(arreglo)
    return arreglo;
  }
  //----------------------------------------------------------------------------
  return{
      numRandom,
      numerosRandom,
      listaRandom
  }
}