import React,{useState} from 'react'
import { BotonLista } from '../Lista/BotonLista';
import { Lista } from '../Lista/Lista'

export const ListaRandomPagina = () => {
  const [tamaño,setTamaño]=useState([]);
  const [lista,setLista]=useState([]);
  const [bandera,setBandera]=useState(true);
  return (
    <main>
      <Lista setTamaño={setTamaño} lista={lista} bandera={bandera}/>
      <BotonLista tamaño={tamaño} setLista={setLista} setBandera={setBandera} bandera={bandera}/>
    </main>
  )
}
